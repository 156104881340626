import * as React from 'react';
import { gql } from '@urql/core';
import { useDynamicIndexPageQuery } from 'generated/graphql';
import IndexPage from 'pages/index';

const DynamicIndexPage = () => {
  const [res] = useDynamicIndexPageQuery();

  if (res.error) {
    return <div>{res.error.message}</div>;
  }

  if (res.fetching || !res.data) {
    return <div>Загрузка...</div>;
  }

  return <IndexPage data={res.data} />;
};

gql`
  query DynamicIndexPageQuery {
    ...IndexPage_data
  }
`;

export default DynamicIndexPage;
